import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import {
  preloadAs,
  preloadType,
} from "decentraland-gatsby/dist/components/Head/Preload"
import useAdvancedUserAgentData from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"
import { useDesktopMediaQuery } from "decentraland-ui2/dist/components/Media"

import { LandingBannerCTA } from "../components/Landing/LandingBannerCTA"
import { LandingFooterV2 } from "../components/Landing/LandingFooterV2"
import { LandingHeroV2 } from "../components/Landing/LandingHeroV2"
import { LandingMissionsV2 } from "../components/Landing/LandingMissionsV2"
import { LandingSocialProof } from "../components/Landing/LandingSocialProof"
import { LandingTextMarquee } from "../components/Landing/LandingTextMarquee"
import { LandingWhatsHot } from "../components/Landing/LandingWhatsHot"
import Layout from "../components/Layout/Layout"
import useLandingContentfulQuery from "../hooks/useLandingContentfulQuery"
import { SegmentEvent } from "../modules/segment"

import "./index.css"

export enum Feed {
  Scenes,
  Events,
  Adventures,
  Multiplayer,
}

export default function IndexPage(props: any) {
  const l = useFormatMessage()

  const isDesktop = useDesktopMediaQuery()

  const [isLoadingUserAgentData] = useAdvancedUserAgentData()

  const landingContentful = useLandingContentfulQuery()

  if (isLoadingUserAgentData) {
    return (
      <Layout hideSocialLinks {...props}>
        <Head
          title={l("index.social.title") || ""}
          description={l("index.social.description") || ""}
          image={landingContentful.herov2.imageLandscape.url}
        >
          <link
            rel="preload"
            href={landingContentful.herov2?.imageLandscape.url}
            type={preloadType(landingContentful.herov2?.imageLandscape.url)}
          />
        </Head>
        <Loader active size="large" />
      </Layout>
    )
  }

  return (
    <Layout hideSocialLinks {...props}>
      <Head
        title={l("index.social.title") || ""}
        description={l("index.social.description") || ""}
        image={landingContentful.herov2.imageLandscape.url}
      >
        <link
          rel="preload"
          href={landingContentful.herov2?.imageLandscape.url}
          as={
            preloadAs(landingContentful.herov2?.imageLandscape.url) || "image"
          }
          type={preloadType(landingContentful.herov2?.imageLandscape.url)}
        />
        <link
          rel="preload"
          href={landingContentful.herov2.videoLandscape.url}
          as={
            preloadAs(landingContentful.herov2?.videoLandscape.url) || "video"
          }
          type={preloadType(landingContentful.herov2?.videoLandscape.url)}
        />
      </Head>
      <LandingHeroV2 hero={landingContentful.herov2} isDesktop={isDesktop} />
      <LandingMissionsV2
        missions={landingContentful.missionsv2}
        isDesktop={isDesktop}
      />
      <LandingBannerCTA
        {...landingContentful.createAvatarBanner}
        eventPlace={SegmentEvent.CreateYourAvatar}
        isDesktop={isDesktop}
      />

      <LandingWhatsHot list={landingContentful.whatsHot.list} />
      <LandingTextMarquee text={landingContentful.textMarquee.text} />

      <LandingSocialProof socialProof={landingContentful.socialProof} />

      <LandingBannerCTA
        {...landingContentful.startExploringBanner}
        eventPlace={SegmentEvent.GoToExplorer}
        isDesktop={isDesktop}
      />
      <LandingFooterV2 />
    </Layout>
  )
}
