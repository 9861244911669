import React, { useCallback } from "react"

import { useInView } from "react-intersection-observer"

import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import useAdvancedUserAgentData from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"
import env from "decentraland-gatsby/dist/utils/env"

import {
  isWebpSupported,
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import { CDNSource, getCDNRelease } from "../../../modules/cdnReleases"
import { SectionViewedTrack } from "../../../modules/segment"
import { setUserAgentArchitectureDefautlByOs } from "../../../modules/userAgent"
import { DownloadButton } from "../../Buttons/DownloadButton"
import { LandingDownloadOptions } from "../LandingDownloadOptions"
import { OperativeSystem } from "../LandingDownloadOptions/LandingDownloadOptions.types"
import {
  Hero,
  HeroActionsContainer,
  HeroChevronContainer,
  HeroContainer,
  HeroContent,
  HeroContentLoading,
  HeroImageContainer,
  HeroSection,
  HeroSubtitle,
  HeroTextContainer,
  HeroTitle,
  HeroVideo,
} from "./LandingHeroV2.styled"
import { LandingHeroV2Props } from "./LandingHeroV2.types"

const DOWNLOAD_SUCCESS_URL = env("DOWNLOAD_SUCCESS_URL", "/download_success")

const LandingHeroV2 = React.memo(
  (props: { hero: LandingHeroV2Props; isDesktop: boolean }) => {
    const { isDesktop } = props
    const {
      title,
      subtitle,
      imageLandscape,
      videoLandscape,
      imagePortrait,
      videoPortrait,
      buttonPrimaryLabel,
      id,
    } = props.hero

    const handleMainCTA = useTrackLinkContext()

    const handleClick = useCallback(
      (event) => {
        event.preventDefault()
        handleMainCTA(event)
        const href = event.target.href
        setTimeout(() => {
          window.location.href = href
        }, 500)
      },
      [handleMainCTA]
    )

    const links = getCDNRelease(CDNSource.LAUNCHER)

    const [isLoadingUserAgentData, userAgentData] = useAdvancedUserAgentData()

    const searchParams = new URLSearchParams(window.location.search)
    const os = searchParams.get("os")

    if (userAgentData && os) {
      setUserAgentArchitectureDefautlByOs(userAgentData, os as OperativeSystem)
    }

    const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

    const imageLandscapeOptimized = useImageOptimization(imageLandscape.url)
    const imagePortraitOptimized = useImageOptimization(imagePortrait.url)
    const videoLandscapeOptimized = useVideoOptimization(videoLandscape?.url)
    const videoPortraitOptimized = useVideoOptimization(videoPortrait?.url)

    return (
      <HeroSection>
        <HeroContainer ref={sectionInView}>
          <Hero data-index={id}>
            <HeroTextContainer>
              <HeroTitle variant="h1">{title}</HeroTitle>
              <HeroSubtitle variant="h3" sx={{ typography: { sm: "h4" } }}>
                {subtitle.text}
              </HeroSubtitle>

              <HeroActionsContainer>
                {isDesktop && links && !isLoadingUserAgentData && (
                  <LandingDownloadOptions
                    userAgentData={userAgentData}
                    links={links}
                    redirectPath={DOWNLOAD_SUCCESS_URL}
                    hideLogo
                  />
                )}
                {!isDesktop && (
                  <>
                    <DownloadButton
                      href={env("DOWNLOAD_URL")!}
                      onClick={handleClick}
                      label={buttonPrimaryLabel!}
                      place={SectionViewedTrack.LandingHero}
                      isFullWidth
                    />
                    <HeroChevronContainer>
                      <ExpandMoreSharpIcon fontSize="inherit" />
                    </HeroChevronContainer>
                  </>
                )}
              </HeroActionsContainer>
            </HeroTextContainer>
            <HeroContent>
              <HeroContentLoading />
              {isDesktop && (
                <>
                  {videoLandscape && (
                    <HeroVideo
                      loop
                      muted
                      autoPlay
                      playsInline={true}
                      width={videoLandscape.width}
                      height={videoLandscape.height}
                      poster={
                        (isWebpSupported() && imageLandscapeOptimized.webp) ||
                        imageLandscapeOptimized.jpg ||
                        imageLandscapeOptimized.optimized
                      }
                      isInView={isSectionInView}
                      source={videoLandscapeOptimized || videoLandscape.url}
                      type={videoLandscape.mimeType}
                    />
                  )}
                  {imageLandscape && (
                    <HeroImageContainer
                      imageUrl={
                        (isWebpSupported() && imageLandscapeOptimized.webp) ||
                        imageLandscapeOptimized.jpg ||
                        imageLandscapeOptimized.optimized
                      }
                    />
                  )}
                </>
              )}
              {!isDesktop && (
                <>
                  {videoPortrait && (
                    <HeroVideo
                      loop
                      muted
                      autoPlay
                      playsInline={true}
                      width={videoPortrait.width}
                      height={videoPortrait.height}
                      poster={
                        (isWebpSupported() && imagePortraitOptimized.webp) ||
                        imagePortraitOptimized.jpg ||
                        imagePortraitOptimized.optimized
                      }
                      isInView={isSectionInView}
                      source={videoPortraitOptimized || videoPortrait.url}
                      type={videoPortrait.mimeType}
                    />
                  )}
                  {imagePortrait && (
                    <HeroImageContainer
                      imageUrl={
                        (isWebpSupported() && imagePortraitOptimized.webp) ||
                        imagePortraitOptimized.jpg ||
                        imagePortraitOptimized.optimized
                      }
                    />
                  )}
                </>
              )}
            </HeroContent>
          </Hero>
        </HeroContainer>
      </HeroSection>
    )
  }
)

export { LandingHeroV2 }
