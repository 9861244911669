import { ContentfulMissionsV2EntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SegmentEvent } from "../../../modules/segment"

enum MissionType {
  CREATE = "create",
  EXPERIENCE = "experience",
  INFLUENCE = "influence",
}

type LandingMissionsV2Props = ContentfulMissionsV2EntriesProps & {
  eventData: SegmentEvent
  isDesktop: boolean
  index?: number
}

type LandingMissionsV2DetailProps = ContentfulMissionsV2EntriesProps & {
  isSectionInView: boolean
}

type LandingMissionV2ViewProps = {
  onIsInView: () => void
  id: string
}

export type {
  LandingMissionsV2Props,
  LandingMissionV2ViewProps,
  LandingMissionsV2DetailProps,
}
export { MissionType }
