import React, { Fragment, useEffect, useState } from "react"

import { useInView } from "react-intersection-observer"

import { ContentfulMissionsV2ListProps } from "../../../hooks/useLandingContentfulQuery"
import { SegmentEvent } from "../../../modules/segment"
import { MissionsContainer, MissionsSection } from "./LandingMissionsV2.styled"
import { MissionType } from "./LandingMissionsV2.types"
import { LandingMissionV2Detail } from "./LandingMissionV2Detail"
import { LandingMissionV2Video } from "./LandingMissionV2Video"
import { LandingMissionV2View } from "./LandingMissionV2View"

const LandingMissionsV2 = React.memo(
  (props: { missions: ContentfulMissionsV2ListProps; isDesktop: boolean }) => {
    const { missions, isDesktop } = props

    const [activeSection, setActiveSection] = useState<MissionType>(
      MissionType.EXPERIENCE
    )

    const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

    useEffect(() => {
      if (!isSectionInView) {
        setActiveSection(MissionType.EXPERIENCE)
      }
    }, [isSectionInView])

    return (
      <MissionsSection>
        <MissionsContainer ref={sectionInView}>
          {missions.list.map((mission, index) => {
            const id = mission.id as MissionType
            return (
              <Fragment key={`${id}-${index}`}>
                <LandingMissionV2View
                  id={id}
                  onIsInView={() => setActiveSection(id)}
                />
                <LandingMissionV2Detail
                  {...mission}
                  isSectionInView={id === activeSection}
                />
                <LandingMissionV2Video
                  {...mission}
                  isSectionInView={id === activeSection}
                  eventData={SegmentEvent.MissionsExperience}
                  isDesktop={isDesktop}
                />
              </Fragment>
            )
          })}
        </MissionsContainer>
      </MissionsSection>
    )
  }
)

export { LandingMissionsV2 }
